import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";

import StatusCard from "../../common/reportsCards/StatusCard";
import ModuleCard from "../../common/reportsCards/ModuleCard";
import TipsCard from "../../common/reportsCards/TipsCard";

import Usage from "../../assets/reportSVG/usage.svg";
import imag from "../../assets/images/moonerfooter.png";
import autoTable from "jspdf-autotable";

import User from "../../assets/reportSVG/user.svg";
import Trending from "../../assets/reportSVG/trending.svg";
import Assessment from "../../assets/reportSVG/assessment.svg";
import Doolar from "../../assets/reportSVG/dollar.png";
import BugReport from "../../assets/reportSVG/bugreport.svg";
import Equalizer from "../../assets/reportSVG/equalizer.svg";
import Facebook from "../../assets/reportSVG/facebook.svg";
import Bookings from "../../assets/reportSVG/bookings.svg";
import Check from "../../assets/reportSVG/check.svg";
import Pdf from "../../assets/reportSVG/pdf.png";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import Table from "./Table";
// import { PdfDocument } from "./pdf";
import { getReport } from "../../redux/actions/reports/report.actions";
import {getAllCategoriesAction} from "../../redux/actions/category/category.action";
import { connect } from "react-redux";
import PdfButton from "./PdfButton";
import Download from "./Download";
import Permissions from "../subAdmin/Permissions";
import { downloadExcel } from "react-export-table-to-excel";

import jsPDF from "jspdf";
import { useState } from "react";
const useStyles = makeStyles((mainTheme) => ({
  title: {
    fontSize: "22px",
    lineHeight: "28px",
    letterSpacing: "0.2em",
    color: "#000000",
    marginLeft: "7px",
    fontWeight: "600",
    marginTop: mainTheme.spacing(7),
    marginBottom: mainTheme.spacing(4),
    [mainTheme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [mainTheme.breakpoints.down("xs")]: {
      marginBottom: mainTheme.spacing(4),
      textAlign: "center",
      fontSize: "18px",
    },
  },
  CardWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  field: {
    width: "95%",
    borderRadius: "15px",
    height: "55px",
    backgroundColor: "#fff",
    fontSize: "14px",
    marginTop: mainTheme.spacing(1),
    marginBottom: mainTheme.spacing(4),
    "& .MuiOutlinedInput-input": {
      [mainTheme.breakpoints.down("lg")]: {
        padding: "15.5px 15px",
      },
    },
    [mainTheme.breakpoints.down("lg")]: {
      width: "90%",
      height: "54px",
    },
  },
  removeOutline: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 0,
      },
      "&:hover fieldset": {
        border: 0,
      },
      "& fieldset": {
        border: 0,
      },
    },
  },
  textStyle: {
    "& .MuiInputBase-input": {
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0.2em",
      color: "#20253B",
      opacity: "0.8",
      backgroundColor: "#fff",
      borderRadius: "20px",
      // height: '5px',
    },
  },
}));

const ReportManagement = ({ categoryData,getAllCategories, getReport, reportData, loading }) => {
  const classes = useStyles();
  const [Date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [catagories, setCatagories] = useState("");
  const [catagoriesList, setCatagoriesList] = useState([]);

  useEffect(() => {
    getReport(Date, endDate,catagories);
  }, [endDate, Date,catagories]);
  //("Report data", reportData);

  useEffect(() => {
    getAllCategories();
  }, []);
  useEffect(() => {
    getCatagories();
  }, [categoryData]);

  const generatePDF = () => {
    console.log("generatePDF");
    var doc = new jsPDF("p", "pt");
    doc.text(200, 50, `Report Management`);
    autoTable(doc, {
      theme: "grid",
      startY: 100,
      styles: { fontSize: 15 },
      columnStyles: {
        0: { cellWidth: "50%", minCellHeight: 40 },
        1: { cellWidth: 100, minCellHeight: 40 },
      },

      body: [
        ["Total Number of Users:", reportData?.service_seeker?.toFixed(2)],
        [
          "Total number of service providers:",
          reportData?.service_provider?.toFixed(2),
        ],
        ["Total number of Bookings:", reportData?.bookings?.toFixed(2)],
        ["Total amount earned on app", reportData?.mooner_earning?.toFixed(2)],
        [
          "Total amount of paid out MNR's:",
          reportData?.total_paid_out?.toFixed(2),
        ],
        ["Total numbers of complaints:", reportData?.complains?.toFixed(2)],
        ["Pending Complaints:", reportData?.pending_complains?.toFixed(2)],
        ["Completed Complaints:", reportData?.pending_complains?.toFixed(2)],
        ["Total App Revenue:", reportData?.app_mln?.toFixed(2)],
        ["Cancelled Bookings:", reportData?.bookings_canceled?.toFixed(2)],
        ["Completed Services:", reportData?.completed_services?.toFixed(2)],
        [
          "Inactive Service Provider:",
          reportData?.inactive_service_provider?.toFixed(2),
        ],
        ["Reffer & MLN in MNR:", reportData?.referrals_mln?.toFixed(2)],
        ["Refund MLN:", reportData?.refund_mln?.toFixed(2)],
        ["Reviews:", reportData?.reviews?.toFixed(2)],
        ["Seeker With Provider:", reportData?.seeker_with_provider?.toFixed(2)],
        [
          "Seeker Without Provider:",
          reportData?.seeker_without_provider?.toFixed(2),
        ],
        ["Tip MLN:", reportData?.tip_mln?.toFixed(2)],
      ],
    });
    var img = new Image();
    img.src = imag;
    doc.addImage(img, "png", 270, 500, 70, 70);
    doc.save("report.pdf");
  };

  //Excel Download

  function handleDownloadExcel() {
    const body = [],
      header = [];
    const format = Object.entries(reportData);
    // //("format", format);
    format?.map((item) => {
      header.push(item[0]?.replaceAll("_", " "));
      body.push("" + item[1]);
    });
    //("header", header);
    //("body", body);

    // const header = ["Firstname", "Lastname", "Age"];
    // const body = [
    //   ["Edison", "Padilla", 14],
    //   ["Cheila", "Rodrigez", 56],
    // ];

    downloadExcel({
      fileName: "Report.xls",
      sheet: "Report.xls",
      tablePayload: {
        header,
        // accept two different data structures
        body: [body],
      },
    });
  }
  const handleDateChange = (e) => {
    //("datechange", e.target.value);
    setDate(e.target.value);
  };

  

  const getCatagories = () => {
    let items = categoryData?.filter((Allcategories) => Allcategories.behaviour === "Default")
      .map((res, i) => {
        return { value: res.id, label: res.name };
      });
    setCatagoriesList(items && items.length > 0 ? items : "");
  };
  const handleChangeCategories = (event) => {
    console.log(event.target.value)
    setCatagories(event.target.value);
  }

  /////////
  return (
    <Container maxWidth="xl">
      <Permissions page="view_tickets" />
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.title}> Report </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
            <Typography >
                    Category
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={[classes.field, classes.removeOutline]}
                  >
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={catagories}
                      onChange={handleChangeCategories}
                      label="categories"
                      className={classes.textStyle}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {catagoriesList &&
                        catagoriesList.map((res, index) => (
                          <MenuItem
                            key={index}
                            className={classes.dropdownMenuStyle}
                            value={res.value}
                          >
                            {res.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>From</Typography>
              <TextField
                onChange={handleDateChange}
                type="date"
                id="outlined-basic"
                variant="outlined"
                placeholder="DD/MM/YYYY"
                className={[classes.field, classes.removeOutline]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>To</Typography>
              <TextField
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                id="outlined-basic"
                variant="outlined"
                name="to"
                placeholder="DD/MM/YYYY"
                className={[classes.field, classes.removeOutline]}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControl
                variant="outlined"
                className={[classes.field, classes.removeOutline]}
              >
                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                <Select
                  //   onChange={handleChangeCountry}
                  //   value={country}
                  name="country"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="subCategories"
                  className={classes.textStyle}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={"global"}>Pak</MenuItem>
                  <MenuItem value={"regional"}>UK</MenuItem>
                  <MenuItem value={"local"}>USA</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className={[classes.field, classes.removeOutline]}
              style={{
                display: "flex",
                backgroundColor: "transparent",
                paddingLeft: "30px",
              }}
            >
              <Download data={reportData} />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.CardWrapper}>
          <ModuleCard
            title="Summary"
            Images={{
              box1: { image1: User },
              box2: { image2: User },
              // box3: { image3: User },
              // box4: { image4: "" },
            }}
            amount={{
              amount1: reportData && reportData.service_seeker,
              amount2: reportData && reportData.service_provider,
              // amount3: reportData && reportData.service_seeker,
              // amount4: "",
            }}
            tagline={{
              tagline1: "Users",
              tagline2: "Service Providers",
              // tagline3: "Users",
              // tagline4: "",
            }}
          />
          <StatusCard
            title="Stats"
            date={Date}
            endDate={endDate}
            Images={{
              box1: { image1: Trending, cursor1: "pointer" },
              box2: { image2: Assessment, cursor2: "pointer" },
              box3: { image3: Trending, cursor3: "pointer" },
              box4: { image4: Doolar, cursor4: "pointer" },
            }}
            amount={{
              amount1: reportData && reportData?.total_paid_out?.toFixed(2),
              amount2: reportData && reportData?.referrals_mln?.toFixed(2),
              amount3: reportData && reportData?.app_mln?.toFixed(2),
              amount4: reportData && reportData?.mooner_earning?.toFixed(2),
              amount5: reportData && reportData?.total_paid_out_sgd?.toFixed(2),
              amount6: reportData && reportData?.referrals_sgd?.toFixed(2),
              amount7: reportData && reportData?.app_sgd?.toFixed(2),
              amount8: reportData && reportData?.mooner_earning_sgd?.toFixed(2),
            }}
            tagline={{
              tagline1: "Total Paid Out",
              tagline2: "Reffer & MLN",
              tagline3: "App Revenue",
              tagline4: "Mooner App",
            }}
          />

          <StatusCard
            title="Complaints"
            date={Date}
            endDate={endDate}
            Images={{
              box1: { image1: BugReport, cursor1: "pointer" },
              box2: { image2: Trending, cursor2: "pointer" },
              box3: { image3: Trending, cursor3: "pointer" },
              box4: { image4: "" },
            }}
            amount={{
              amount1: reportData && reportData.complains,
              amount2: reportData && reportData.pending_complains,
              amount3: reportData && reportData.completed_complains,
              amount4: "",
            }}
            tagline={{
              tagline1: "Total Complaints",
              tagline2: "Pending Complaints",
              tagline3: "Completed Complaints",
              tagline4: "",
            }}
          />

          <ModuleCard
            title="Refund & Reviews"
            date={Date}
            endDate={endDate}
            Images={{
              box1: { image1: Trending, cursor1: "pointer" },
              // box2: { image2: Doolar },
              // box3: { image3: Trending },
              box2: { image2: Assessment, cursor2: "pointer" },
            }}
            amount={{
              amount1: reportData && reportData?.refund_mln?.toFixed(2),
              // amount2: "60",
              // amount3: "30",
              amount2: reportData && reportData.reviews,
            }}
            tagline={{
              tagline1: "Total Refund($)",
              // tagline2: "Completed Refund",
              // tagline3: "Pending Refund",
              tagline2: "Total Reviews",
            }}
          />

          <StatusCard
            title="Generate Report"
            generatePDF={generatePDF}
            handleDownloadExcel={handleDownloadExcel}
            Images={{
              box1: { image1: Pdf, cursor1: "pointer" },
              box2: { image2: "" },
              box3: { image3: Trending, cursor3: "pointer" },
              box4: { image4: "" },
            }}
            amount={{
              amount1: "Download PDF",
              amount2: "",
              amount3: "Download XLS",
              amount4: "",
            }}
            tagline={{
              tagline1: "",
              tagline2: "",
              tagline3: "",
              tagline4: "",
            }}
          />

          <ModuleCard
            title="Booking & Providers"
            date={Date}
            endDate={endDate}
            Images={{
              box1: { image1: Bookings, cursor1: "pointer" },
              box2: { image2: Check, cursor2: "pointer" },
            }}
            amount={{
              amount1: reportData && reportData.bookings,
              amount2: reportData && reportData.completed_services,
            }}
            tagline={{
              tagline1: "Bookings",
              tagline2: "Completed Services",
            }}
          />

          <TipsCard
            title="Tips"
            date={Date}
            endDate={endDate}
            Images={{
              box1: { image1: Bookings, cursor1: "pointer" },
              box2: { image2: Check },
            }}
            amount={{
              amount1: reportData && reportData?.tip_mln?.toFixed(2),
              amount2:
                reportData && reportData?.inactive_service_provider?.toFixed(2),
              amount3: reportData && reportData?.tip_sgd?.toFixed(2),
            }}
            tagline={{
              tagline1: "Tips To SP",
              tagline2: "Inactive SP",
            }}
          />

          <ModuleCard
            title="Inactive MLN Seeker "
            Images={{
              box1: { image1: Bookings },
              box2: { image2: Check },
            }}
            amount={{
              amount1: reportData && reportData?.seeker_with_provider,
              amount2: reportData && reportData?.seeker_without_provider,
            }}
            tagline={{
              tagline1: "Seeker With Provider",
              tagline2: "Seeker Without Provider",
            }}
          />

          <ModuleCard
            title=" Cancellation "
            Images={{
              box1: { image1: Doolar },
              // box2: { image2: Trending },
            }}
            amount={{
              amount1: reportData && reportData.bookings_canceled,
              // amount2: "110",
            }}
            tagline={{
              tagline1: "Order Cancellation",
              // tagline2: "Completed Cancellation",
            }}
          />
        </div>
      )}
    </Container>
  );
};
const mapStateToProps = ({ category,report, loader }) => {
  return {
    reportData: report?.data,
    loading: loader.loading,
    categoryData: category.data,
  };
};
export default connect(mapStateToProps, { 
  getReport,
  getAllCategories: getAllCategoriesAction,
 })(ReportManagement);
