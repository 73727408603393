export const base_url_staging =
  "https://mooner-staging-files.s3-us-west-2.amazonaws.com/";
export const base_url_production =
  "https://mooner-production-files.s3.us-west-2.amazonaws.com/";
export const base_url_file_production =
  "https://mooner-production-media.s3.us-west-2.amazonaws.com/";
export const base_url_file_staging =
  "https://mooner-staging-media.s3.us-east-2.amazonaws.com/";

export const hostname = window.location.hostname;

export const testingServer =
  hostname.includes("localhost") || hostname.includes("staging") ? true : false;

export const base_url_auto = testingServer
  ? base_url_staging
  : base_url_production;

export const base_url_file_auto = testingServer
  ? base_url_file_staging
  : base_url_file_production;
